import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import "firebase/firestore"
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCQABdm1uXikB1hvdPS1oDTwKUmFb0XJPk",
  authDomain: "secret-santa-7df5d.firebaseapp.com",
  projectId: "secret-santa-7df5d",
  storageBucket: "secret-santa-7df5d.appspot.com",
  messagingSenderId: "793527057819",
  appId: "1:793527057819:web:517e3db0f0a1ee9189353e",
  measurementId: "G-164V68TFXG"
};

// Initialize Firebase
const fbApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(fbApp);

export const db = getFirestore(fbApp);