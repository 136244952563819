import {useState, createContext} from "react"
import './App.css';
import Login from './components/login/login'
import Review from './components/review/review'
import Background from './components/background/background'

export const LoginContext = createContext(null);
export const AppContext = createContext(null);

function App() {

  const [currentUser, setCurrentUser] = useState(null);
  const [currentAppStatus, setAppStatus] = useState(null);

  return (
    <div className="App">
      <AppContext.Provider value={{currentAppStatus, setAppStatus}}>
      <LoginContext.Provider value={{currentUser, setCurrentUser}}>
      <Background />
      <div className="glowHelper"></div>
      <div className="title">FRIENDSGIVINGMAS</div>
      <div className="subtitle">2023 EDITION</div>
      {currentUser ? <Review context={LoginContext} appContext={AppContext}/> : <Login context={LoginContext} />}
      </LoginContext.Provider>
      </AppContext.Provider>
    </div>
  );
}

export default App;
