import React from "react";
import { useState, useContext } from "react";
import { collection, addDoc, getDocs } from "firebase/firestore";
import { db } from "../../utils/firebase";

import "./login.scss";

const Login = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [failure, setFailure] = useState("");
  const { currentUser, setCurrentUser } = useContext(props.context);

  const register = async () => {
    console.log(userExists());
    if (await userExists()) {
      setFailure("* This name already exists! Please login with your PIN.");
    } else if (username.length === 0 || password.length === 0) {
      setFailure("* Please provide a username and a password.");
    } else {
      try {
        const docRef = await addDoc(collection(db, "users"), {
          username: username,
          password: password,
          submission: "",
        });
        console.log(docRef);
        setCurrentUser({ ref: docRef });
        login();
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    }
  };

  const login = async () => {
    await getDocs(collection(db, "users")).then((querySnapshot) => {
      const allUsers = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      if (
        allUsers.filter(
          (item) => item.username === username && item.password === password
        ).length > 0
      ) {
        setCurrentUser(allUsers.find((item) => item.username === username));
      } else {
        setFailure(
          "* Login failed. Username or password was incorrect or does not exist."
        );
      }
    });
  };

  const userExists = async () => {
    await getDocs(collection(db, "users")).then((querySnapshot) => {
      const allUsers = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      console.log(allUsers);
      if (allUsers.filter((item) => item.username === username).length > 0) {
        console.log("found someone");
        return true;
      } else {
        return false;
      }
    });
  };

  return (
    <div className="login">
      <div className="login-box">
        {failure ? <div className="failure-message">{failure}</div> : null}
        <label htmlFor="">NAME</label>
        <input
          type="text"
          value={username}
          onChange={(e) => {
            setUsername(e.target.value);
          }}
        />
        <label htmlFor="">PIN</label>
        <input
          type="password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
        <div className="footer">
          <div className="loginSubmit link" onClick={() => login()}>
            Login
          </div>{" "}
          |
          <div className="register link" onClick={() => register()}>
            Register
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
