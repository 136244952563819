import React from "react";
import { useState, useContext, useEffect } from "react";
import {
  collection,
  addDoc,
  getDoc,
  getDocs,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../utils/firebase";

import "./review.scss";

const Review = (props) => {
  const [allUsers, setAllUsers] = useState([]);
  const [status, setNewStatus] = useState("Not Saved ✘");
  const { currentUser, setCurrentUser } = useContext(props.context);
  const { currentAppStatus, setAppStatus } = useContext(props.appContext);

  useEffect(() => {
    getUserInfo();
    getAppInfo();
  }, [status]);

  // Check if all registered users have submitted a word to show the admin the Assign button
  const readyToAssign = () => {
    return (
      allUsers.filter((item) => !item.submission).length === 0 &&
      allUsers.length > 1 &&
      currentAppStatus.assigned === false &&
      // In the future this should check if the user is the admin for the given instance
      // instead of username = Joshua
      currentUser.username === "Joshua"
    );
  };

  // Get all user info but hide submission data to prevent peeking
  const getUserInfo = async () => {
    await getDocs(collection(db, "users")).then((querySnapshot) => {
      const userData = querySnapshot.docs.map((doc) => ({
        username: doc.data().username,
        submission: doc.data().submission ? true : false,
        id: doc.id,
        assignedWord: doc.assignedWord,
      }));
      setAllUsers(userData);
      if (currentUser.submission) setNewStatus("Saved ✔");
    });
  };

  // Get instance info to check if already assigned. in the future docRef should point to each instance when clicked into
  const getAppInfo = async () => {
    const docRef = doc(db, "app", "oiUB0eseDQZWvqlUDBST");
    await getDoc(docRef).then((qs) => {
      console.log(qs);
      const appData = {
        assigned: qs.data().assigned,
      };
      setAppStatus(appData);
    });
  };

  const handleChange = async (val) => {
    console.log(currentUser);
    setNewStatus("Saving...");
    const submissionRef = doc(db, "users", currentUser.id);
    await updateDoc(submissionRef, {
      submission: val,
    });
    setNewStatus("Saved ✔");
  };

  const assignUsers = async () => {
    // Each user must have someone assigned to them, and be assigned to someone.
    // Each user can only be assigned to someone once.
    var assignedUsers;
    do {
      assignedUsers = [];
      let unassigned = allUsers.map((item) => item);
      let assigned = [];
      console.log(allUsers);
      assignedUsers = allUsers.map((user) => {
        let randomValue;
        do {
          randomValue = Math.floor(Math.random() * unassigned.length);
          // Handle case such that the last person who is unassigned is also the last person to assign. Restart.
          if (
            unassigned[randomValue].username === user.username &&
            unassigned.length === 1
          )
            break;
        } while (unassigned[randomValue].username === user.username);
        user.assignedTo = unassigned[randomValue];
        assigned.push(unassigned.splice(randomValue, 1));
        if (user.username === "Ray" && user.assignedTo.username === "Charlotte")
          console.log("Unsavory Matching");
        if (user.username === "Charlotte" && user.assignedTo.username === "Ray")
          console.log("Unsavory Matching");
        return user;
      });
      console.log("Final");
      console.log(assignedUsers);
      console.log("Assigned");
      console.log(assigned);
      console.log("Unassigned");
      console.log(unassigned);

      // Redo if Ray is assigned to James or Charlotte and vice-versa
    } while (assignedUsers.length < allUsers.length);

    //Send words for each assigned user to the database
    assignedUsers.map(async (user) => {
      const submissionRef = doc(db, "users", user.id);
      const receivedRef = doc(db, "users", user.assignedTo.id);
      const secretWord = await getDoc(receivedRef);
      await updateDoc(submissionRef, {
        assignedWord: secretWord.data().submission,
      });
    });

    //Tell database that word association has been completed
    const submissionRef = doc(db, "app", "oiUB0eseDQZWvqlUDBST");
    await updateDoc(submissionRef, {
      assigned: true,
    });
    return assignedUsers;
  };

  return (
    <div className="review">
      <div className="review-box">
        <div className="greeting">
          <img src="cutesnowman.png"></img>
          <div className="greeting-text">
            {`${[
              "Howdy",
              "Ahoy",
              "Hello",
              "Seasons Greetings",
              "Hi!",
              "Hola",
              "Konnichiwa",
              "Bonjour",
              "Flameo",
              "Hello there",
              "Yo",
              "Wazzup",
              "Hey",
              "Salutations",
            ]
              .sort(() => Math.random() - Math.random())
              .slice(0, 1)}, ${currentUser.username}`}
          </div>
        </div>
        {!currentAppStatus?.assigned ? (
          <div className="word-section">
            <div className="personal-word">
              <div className="label-text">YOUR WORD IS</div>
              <span
                className="secret-word glow"
                role="textbox"
                contentEditable
                onBlur={(e) => handleChange(e.target.innerHTML)}
              >
                {currentUser.submission ? currentUser.submission : "NOTHING"}
              </span>
            </div>
            <div
              className={`${
                status.includes("Not Saved")
                  ? "unsaved"
                  : status === "Saving..."
                  ? "saving"
                  : "saved"
              }`}
            >
              {status}
            </div>
          </div>
        ) : (
          <div className="word-section">
            <div className="received-word">
              <div className="label-text">
                YOUR <span className="bold">SECRET SANTA'S</span> WORD IS
              </div>
              <span className="colorful" role="textbox">
                {currentUser.assignedWord.toLowerCase()}
              </span>
            </div>
            <div className="submitted-word">
              <div className="label-text small">
                THE WORD <span className="bold">YOU</span> SUBMITTED WAS
              </div>
              <div className="secret-word glow small">
                {currentUser.submission}
              </div>
            </div>
          </div>
        )}

        {currentAppStatus && readyToAssign() ? (
          <button className="winter-button" onClick={() => assignUsers()}>
            ASSIGN WORDS
          </button>
        ) : null}

        {!currentAppStatus?.assigned ? (
          <div className="word-status-section">
            <div className="submitted-section">
              <div className="section-title">
                WORD SUBMITTED (
                {
                  allUsers.filter((user) => {
                    return user.submission;
                  }).length
                }
                )
              </div>
              <div className="users">
                {allUsers
                  .filter((user) => {
                    return user.submission;
                  })
                  .map((user) => {
                    return (
                      <div className="user submitted">{user.username}</div>
                    );
                  })}
              </div>
            </div>
            <div className="unsubmitted-section">
              <div className="section-title">
                AWAITING WORD (
                {
                  allUsers.filter((user) => {
                    return !user.submission;
                  }).length
                }
                )
              </div>
              <div className="users">
                {allUsers
                  .filter((user) => {
                    return !user.submission;
                  })
                  .map((user) => {
                    return <div className="user waiting">{user.username}</div>;
                  })}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Review;
